import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

//グローバル関数の導入
import gf from './mixins/globalFunctions';
Vue.mixin(gf);

import sc from './mixins/controlQuiz';
Vue.mixin(sc);

import utils from './mixins/utils';
Vue.mixin(utils);

Vue.config.productionTip = false;

import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_apiKey,
  authDomain: process.env.VUE_APP_FIREBASE_authDomain,
  projectId: process.env.VUE_APP_FIREBASE_projectId,
  storageBucket: process.env.VUE_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.VUE_APP_FIREBASE_messagingSenderId,
  appId: process.env.VUE_APP_FIREBASE_appId,
  measurementId: process.env.VUE_APP_FIREBASE_measurementId,
};

const app = initializeApp(firebaseConfig);

//呼び出し関数の設定
import { getFunctions } from 'firebase/functions';
getFunctions(app);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
