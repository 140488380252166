import {
  getAuth,
  //onAuthStateChanged,
  //signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  // updatePassword,
  // sendPasswordResetEmail,
  // updateEmail,
} from 'firebase/auth';

import {
  getFirestore,
  collection,
  getDoc,
  doc,
  setDoc,
  getDocs,
} from 'firebase/firestore';

import { getStorage, ref, getMetadata, listAll } from '@firebase/storage';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState([]),
  },
  methods: {
    //--------------------------------------------------------------------------------------チーム開発用にここから新関数として抽象化する
    //========================================================================
    //Authenticationの操作
    //------------------------------------
    async utils_login_auth(obj) {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      let method = obj.method;
      let email = obj.email;
      let password = obj.password;
      switch (method) {
        case 'signInWithPopup':
          method = signInWithPopup(auth, provider);
          break;
        case 'signInWithEmailAndPassword':
          method = signInWithEmailAndPassword(auth, email, password);
          break;
        default:
      }
      return await method
        .then(async (res) => {
          return { flag: true, user: res.user };
        })
        .catch(() => {
          return { flag: false };
        });
    },
    async utils_get_atuhUserClaims() {
      const auth = getAuth();
      return await auth.currentUser
        .getIdTokenResult(auth)
        .then((res) => {
          return { flag: true, claims: res.claims };
        })
        .catch(() => {
          return { flag: false };
        });
    },
    async utils_create_authUser(obj) {
      const auth = getAuth();
      const method = createUserWithEmailAndPassword(
        auth,
        obj.email,
        obj.password,
      );
      return await method
        .then((res) => {
          return { flag: true, user: res.user };
        })
        .catch(() => {
          return { flag: false };
        });
    },
    async utils_signOut_auth() {
      return await signOut(getAuth())
        .then(() => {
          return true;
        })
        .catch((error) => {
          alert(error.message);
          return false;
        });
    },

    //Firestoreの操作
    //------------------------------------
    async utils_merge_firestore(collectionName, documentName, obj) {
      const ref = doc(collection(getFirestore(), collectionName), documentName);
      return await setDoc(ref, obj, { merge: true });
    },
    async utils_get_firestore(collectionName, documentName) {
      const docment = doc(
        collection(getFirestore(), collectionName),
        documentName,
      );
      return await getDoc(docment).then((docSnap) => {
        return docSnap.data();
      });
    },
    async utils_get_subCollections(colleName, documentName, subcolleName) {
      const collePath = collection(
        getFirestore(),
        colleName,
        documentName,
        subcolleName,
      );

      return await getDocs(collePath).then((docSnap) => {
        const obj = {};
        docSnap.forEach((snap) => {
          obj[snap.id] = snap.data();
        });
        return obj;
      });
    },
    //usersデータの更新。
    utils_merge_userFS(kamokuName, obj) {
      const auth = getAuth();
      const uid = auth.currentUser.uid;

      return new Promise((resolve) => {
        Object.keys(obj).forEach((documentName) => {
          const data = {
            //FSではdataフィールドにすべてぶち込んでいきます。インデックス対策
            data: obj[documentName],
          };
          const ref = doc(
            getFirestore(),
            'users',
            uid,
            kamokuName,
            documentName,
          );
          setDoc(ref, data, { merge: true });
        });
        resolve(true);
      });
    },

    //Storageの操作
    //------------------------------------
    utils_create_storageUrl(fullPath) {
      const storage = getStorage();
      const bucket = storage._bucket.bucket;
      let url = 'https://firebasestorage.googleapis.com/v0/b/';
      url += bucket;
      url += '/o/';
      url += encodeURIComponent(fullPath);
      url += '?alt=media&token=' + this.utils_get_randStr(5);
      /*
      基本tokenは不要
      ただし、画像の変更前と変更後でURLが全く同じだと、描画が更新されない
      ランダム文字列をtokenとして付与することで、更新前と更新後の文字列に差を付ける
      */
      return url;
    },
    utils_get_storageAllFiles(directoryPath) {
      const listRef = ref(getStorage(), directoryPath);
      return listAll(listRef);
    },
    utils_get_storageFileMetadata(fullPath) {
      const metadataRef = ref(getStorage(), fullPath);
      return getMetadata(metadataRef);
    },

    //データの操作
    //------------------------------------
    utils_deepCopy(data) {
      const toString = Object.prototype.toString;
      let type = toString.call(data);

      if (type == '[object Object]') {
        return { ...data };
      }

      if (type == '[object Array]') {
        if (data[0] == undefined) {
          return [];
        }
        type = toString.call(data[0]);
        if (type == '[object Object]') {
          return data.map((obj) =>
            Object.assign({}, JSON.parse(JSON.stringify(obj))),
          );
        } else {
          return [...data];
        }
      }
    },
    //オブジェクトからオブジェクトの配列を作成する。
    utils_create_arrayObjFromObj(obj) {
      const obj_new = this.utils_deepCopy(obj);
      const arr_keys = Object.keys(obj_new);
      if (arr_keys.length == 0) {
        return [];
      }
      const arrayObj = [];
      arr_keys.forEach((key) => {
        arrayObj.push(obj_new[key]);
      });

      arrayObj.sort(function (a, b) {
        if ('ind' in a && 'ind' in b) {
          return a.ind - b.ind;
        }

        if ('ind' in a) {
          return -1;
        }

        if ('ind' in b) {
          return 1;
        }

        return 0;
      });
      return arrayObj;
    },
    //ランダムでN桁のIDを生成する
    utils_get_randStr(N) {
      const S =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const str = Array.from(Array(N))
        .map(() => S[Math.floor(Math.random() * S.length)])
        .join('');
      return str;
    },
    //日付をフォーマットして返す
    utils_formatDate(dateObj, format) {
      var dayArr = new Array('日', '月', '火', '水', '木', '金', '土');
      var Y = dateObj.getFullYear();
      var M = this.for2D(dateObj.getMonth() + 1);
      var D = this.for2D(dateObj.getDate());
      var h = this.for2D(dateObj.getHours());
      var m = this.for2D(dateObj.getMinutes());
      var ind = dateObj.getDay();
      var day = dayArr[ind];

      format = format.replace(/Y/, Y);
      format = format.replace(/M/, M);
      format = format.replace(/D/, D);
      format = format.replace(/h/, h);
      format = format.replace(/m/, m);
      format = format.replace(/day/, day);
      return format;
    },
    //0埋めして2桁に削る
    for2D(data) {
      const str = String(data);
      const TwoDigits = ('0' + str).slice(-2);
      return TwoDigits;
    },
    //秒数を**:**:**表記にして返却
    utils_formatSecond(sec) {
      const h = Math.floor(sec / (60 * 60));
      const m_s = sec % (60 * 60);
      const m = Math.floor(m_s / 60);
      const s = m_s % 60;
      const arr = [h.toString(), m.toString(), s.toString()];
      let timeStrings = arr.map(function (str) {
        if (str.length < 2) {
          return '0' + str;
        }
        return str;
      });
      return timeStrings[0] + ':' + timeStrings[1] + ':' + timeStrings[2];
    },
  },
};
