import {
  getAuth,
  onAuthStateChanged,
  //signInWithRedirect,
  // signInWithPopup,
  // GoogleAuthProvider,
  // signOut,
  // createUserWithEmailAndPassword,
  // signInWithEmailAndPassword,
  updatePassword,
  sendPasswordResetEmail,
  updateEmail,
} from 'firebase/auth';

import // getFirestore,
// collection,
// getDoc,
// doc,
//setDoc,
//getDocs,
'firebase/firestore';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['webApp', 'conf', 'user']),
  },
  methods: {
    //========================================================================
    //リロード時に設定ファイルを再度読み込んで状態を維持させる
    async gf_keepActive() {
      if (this.webApp.isActive) {
        return true;
      }
      const obj_conf = await this.utils_get_firestore('publicData', 'conf');
      this.$store.commit('update_store', {
        path: 'state',
        key: 'conf',
        data: obj_conf,
      });
      this.$store.commit('update_store', {
        path: 'webApp',
        key: 'isActive',
        data: true,
      });
    },
    gf_moveToTop() {
      if (this.$route.path != '/home') {
        this.$router.push('/home');
      } else {
        return;
      }
    },
    gf_moveToRoot() {
      if (this.$route.path != '/') {
        this.$router.push('/');
      } else {
        return;
      }
    },

    //ログインユーザー関連
    //========================================================================
    //一連のログイン処理を実行する
    async gf_login_uzcolletest(obj) {
      const obj_login = await this.utils_login_auth(obj);
      if (!obj_login.flag) {
        return false;
      }

      const obj_claims = await this.utils_get_atuhUserClaims();
      if (!obj_claims.flag) {
        return false;
      }

      await this.create_userDocument_asNeeded();
      const obj_user = {
        auth: obj_login.user,
        claims: obj_claims.claims,
      };
      this.update_userLoginStatus(obj_user);

      return true;
    },
    //一連のサインアウト処理を実行する
    async gf_signOut_uzcolletest() {
      return this.utils_signOut_auth().then(() => {
        const init_obj = {
          auth: {},
          claims: {},
        };

        this.update_userLoginStatus(init_obj);
        return true;
      });
    },
    //一連のアカウント作成処理を実行する
    async gf_create_uzcolletest(obj) {
      const obj_create = await this.utils_create_authUser(obj);
      if (!obj_create.flag) {
        return false;
      }
      const obj_claims = await this.utils_get_atuhUserClaims();
      if (!obj_claims.flag) {
        return false;
      }
      await this.create_userDocument_asNeeded();

      const obj_user = {
        auth: obj_create.user,
        claims: obj_claims.claims,
      };
      await this.update_userLoginStatus(obj_user);
      return true;
    },
    //firestoreにユーザードキュメントが無ければ作成する
    create_userDocument_asNeeded() {
      const auth = getAuth();
      const user = auth.currentUser;
      const uid = user.uid;
      this.utils_get_firestore('users', uid).then((data) => {
        if (data == undefined) {
          const existingInflowOrigin = localStorage.getItem('inflowOrigin');
          const registerObj = existingInflowOrigin
            ? { inflowOrigin: { ...JSON.parse(existingInflowOrigin) } }
            : {};
          this.utils_merge_firestore('users', uid, registerObj);
        }
      });
    },
    //ログインしているユーザー情報をstoreに格納する
    update_userLoginStatus(obj) {
      this.$store.commit('update_store', {
        path: 'user',
        key: 'auth',
        data: obj.auth,
      });
      this.$store.commit('update_store', {
        path: 'user',
        key: 'claims',
        data: obj.claims,
      });
    },
    //ログイン済み（ユーザーデータがある）か調べる
    gf_isLogin() {
      //stringifyをしないと、{__ob: observer}が返り、length == 0判定できない
      const obj_auth = JSON.parse(JSON.stringify(this.$store.state.user.auth));
      if (Object.keys(obj_auth).length == 0) {
        return false;
      } else {
        return true;
      }
    },
    // ログインしているユーザーはリロード時にも維持できるように引き出す
    gf_keepLogin() {
      return new Promise((resolve) => {
        if (!this.gf_isLogin()) {
          const auth = getAuth();
          var unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
              const obj_claims = await this.utils_get_atuhUserClaims();
              if (!obj_claims.flag) {
                resolve(false);
              }
              const obj_user = {
                auth: user,
                claims: obj_claims.claims,
              };
              await this.update_userLoginStatus(obj_user);
              await this.gf_getAssignment();
              resolve(true);
            } else {
              resolve(false);
            }
            unsubscribe(); // 登録解除
          });
        } else {
          resolve(true);
        }
      });
    },
    //keepLoginとAssignment.vueで実行。
    async gf_getAssignment(isforce) {
      const companyId = this.user.claims.companyId;
      if (companyId == undefined || companyId == '') {
        return; //団体管理されていなければ返す
      }

      let obj_assignment = JSON.parse(JSON.stringify(this.user.assignment));
      if (Object.keys(obj_assignment).length != 0) {
        if (isforce) {
          //強制ロードなので処理を続行
        } else {
          return; //ロード済みであれば返す
        }
      }

      const groupId = this.user.claims.groupId;
      const obj = await this.utils_get_firestore('assignment', companyId);
      if (obj === undefined) {
        this.$store.commit('update_store', {
          path: 'user.assignment',
          key: 'data',
          data: {},
        });
        return;
      }

      obj_assignment = obj.data; //インデックス除外のためのdataプロパティに注意。

      let obj_group = {};
      Object.keys(obj_assignment).forEach((ms) => {
        const ele = obj_assignment[ms];
        if (ele.groupId == groupId) {
          obj_group[ms] = ele;
        }
      });

      this.$store.commit('update_store', {
        path: 'user.assignment',
        key: 'data',
        data: obj_group,
      });
    },
    async gf_resetPassWithEmail(email) {
      //パスワードの再設定メールを送る, root
      const auth = getAuth();
      const method = sendPasswordResetEmail(auth, email);
      return await method
        .then((res) => {
          return { flag: true, result: res }; //成功の場合resはundifinedか？
        })
        .catch((error) => {
          return { flag: false, result: error.message };
        });
    },
    gf_updatePassword(newPassword) {
      //パスワードの変更, マイアカウント
      return new Promise((resolve) => {
        const auth = getAuth();
        const user = auth.currentUser;
        updatePassword(user, newPassword)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            alert(
              'ログインし直してもう一度お試しください。\r\n' + error.message,
            );
            resolve(false);
          });
      });
    },
    gf_updateEmail(newEmail) {
      return new Promise((resolve) => {
        const auth = getAuth();
        updateEmail(auth.currentUser, newEmail)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            alert(
              'ログインし直してもう一度お試しください。\r\n' + error.message,
            );
            resolve(false);
          });
      });
    },

    //科目関連の処理
    //========================================================================
    //科目ページ共通の処理
    gf_set_kamokuPage() {
      this.$store.commit('mainHeader', true);
      this.gf_switchDrawerList('questions');
      this.$store.commit('initializeState', { path: 'saveData' });
      this.$store.commit('update_store', {
        path: 'webApp.drawer',
        key: 'isShow',
        data: false,
      });
    },

    //------------------------------------------------------------------------------ここから旧データ用の処理
    //【旧データ用】科目のデータを引き出し、sectionObjを作成する（学習項目一覧をセットする）
    async gf_set_kamokuData(kamokuName) {
      //defaultの科目オブジェクトをdeepコピーしてstateに格納
      const kamokuDefault = this.utils_deepCopy(
        this.$store.state.kamokuDefault,
      );
      this.$store.commit('update_store', {
        path: 'state',
        key: kamokuName,
        data: kamokuDefault,
      });

      //科目データの取得
      return await this.utils_get_firestore('public', kamokuName)
        .then((obj) => {
          this.$store.commit('update_store', {
            path: kamokuName,
            key: 'questions',
            data: obj,
          });
          this.$store.commit('update_store', {
            path: kamokuName,
            key: 'isLoad',
            data: true,
          });

          //セクションオブジェクトの作成
          this.gf_create_sectionObj_main(kamokuName);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    //【旧データ】セクションオブジェクトの作成
    async gf_create_sectionObj_main(kamokuName) {
      const obj_sectionList =
        this.$store.state[kamokuName].questions['sectionList'];
      const arrayObj_sectionList =
        this.utils_create_arrayObjFromObj(obj_sectionList);

      //データ構造はstateのsctionObjを見てね
      let sectionObj = {};
      for (var i = 0; i < arrayObj_sectionList.length; i++) {
        const sectionId = Number(arrayObj_sectionList[i]['sectionId']);
        if (sectionId % 100 == 0) {
          //単元の最初を発見する100, 200, 300...
          const tangenId = sectionId; //単元データのみ、tngenId == sectionIdになるよ
          const arrSections = arrayObj_sectionList.filter(
            (data) =>
              data['sectionId'] > tangenId &&
              data['sectionId'] < tangenId + 100,
          );

          let obj_sections = {}; //sectionsは二次元配列なので、sentionIdをKeyとしたオブジェクトに直す
          arrSections.forEach((ele) => {
            const sectionId = ele['sectionId'];
            const amount = this.get_questionsAmountInSection(
              kamokuName,
              sectionId,
            );

            //問題数量 = 未回答の量で取り扱う
            ele.unanswered = amount;
            ele.incorrect = 0;
            ele.correctOnce = 0;
            ele.correctConstant = 0;

            //科目ページmounted > gf_set_userKamokuData > insertUserData > get_userQestionState にて表示内容を編集している
            ele.text_amount = amount;
            ele.buffer = 100;

            obj_sections[sectionId] = ele;
          });

          arrayObj_sectionList[i]['sections'] = obj_sections;
          sectionObj[tangenId] = arrayObj_sectionList[i];
        }
      }

      this.$store.commit('update_store', {
        path: kamokuName,
        key: 'sectionObj',
        data: sectionObj,
      });
    },
    //------------------------------------------------------------------------------ここまで旧データ用の処理

    //【新データ用】科目のデータを引き出し、sectionObjを作成する（学習項目一覧をセットする）
    async gf_set_subjectData(kamokuName) {
      //defaultの科目オブジェクトをdeepコピーしてstateに格納
      const kamokuDefault = this.utils_deepCopy(
        this.$store.state.kamokuDefault,
      );
      this.$store.commit('update_store', {
        path: 'state',
        key: kamokuName,
        data: kamokuDefault,
      });

      //科目データの取得
      return await this.utils_get_firestore('publicData', kamokuName)
        .then((obj) => {
          this.$store.commit('update_store', {
            path: kamokuName,
            key: 'questions',
            data: obj,
          });
          this.$store.commit('update_store', {
            path: kamokuName,
            key: 'isLoad',
            data: true,
          });

          //セクションオブジェクトの作成
          this.gf_create_sectionObject_main(kamokuName);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    //【新データ】セクションオブジェクトの作成
    async gf_create_sectionObject_main(kamokuName) {
      const arrayObj_sectionsList = this.utils_create_arrayObjFromObj(
        this.$store.state[kamokuName].questions['sectionsList'],
      );

      const arrayObj_tangensList = this.utils_create_arrayObjFromObj(
        this.$store.state[kamokuName].questions['tangensList'],
      );

      //データ構造はstateのsctionObjを見てね
      let sectionObj = {};
      arrayObj_tangensList.forEach((obj_tangen) => {
        const tangenId = obj_tangen.id;
        const obj_sections = this.get_obj_sections(
          kamokuName,
          arrayObj_sectionsList,
          tangenId,
        );

        obj_tangen['sections'] = obj_sections;
        sectionObj[tangenId] = obj_tangen; //v-forはkey = ind にするので、sectionObjのkeyはtatngenIdで問題ない
      });

      this.$store.commit('update_store', {
        path: kamokuName,
        key: 'sectionObj',
        data: sectionObj,
      });
    },
    get_obj_sections(kamokuName, arrayObj_sectionsList, tangenId) {
      const arrayObj_sections = arrayObj_sectionsList.filter(
        (data) => data.tangenId === tangenId,
      );

      let obj_sections = {}; //sectionsは二次元配列なので、sentionIdをKeyとしたオブジェクトに直す
      arrayObj_sections.forEach((ele) => {
        const sectionId = ele.id;
        const amount = this.get_questionsAmountInSection(kamokuName, sectionId);

        //問題数量 = 未回答の量で取り扱う
        ele.unanswered = amount;
        ele.incorrect = 0;
        ele.correctOnce = 0;
        ele.correctConstant = 0;

        //科目ページmounted > gf_set_userKamokuData > insertUserData > get_userQestionState にて表示内容を編集している
        ele.text_amount = amount;
        if (amount === 0) {
          ele.text_amount = '作問中…';
        }

        ele.buffer = 0;

        obj_sections[sectionId] = ele; //v-forはkey = ind にするので、sectionObjのkeyはsectionIdで問題ない
      });

      return obj_sections;
    },

    get_questionsAmountInSection(kamokuName, sectionId) {
      const obj_questions = this.$store.state[kamokuName].questions.questions;
      const arrayObj_questions =
        this.utils_create_arrayObjFromObj(obj_questions);

      let amount = 0;
      arrayObj_questions.forEach((ele) => {
        if (ele.sectionId != sectionId) {
          return;
        }
        if (ele.status == 'private') {
          return;
        }
        amount++;
      });

      return amount;
    },

    //ユーザー情報を科目データに反映させる
    async gf_set_userKamokuData(kamokuName) {
      //科目Obj（入れ物）があるか確認し無ければ作成、ユーザーの科目データを取得する
      await this.prepare_userKamokuCollection(kamokuName);

      //sectionObjにユーザーデータを統合する
      await this.insertUserData(kamokuName);
    },
    async prepare_userKamokuCollection(kamokuName) {
      return new Promise((resolve) => {
        const uid = this.user.auth.uid;
        if (uid == undefined) {
          //この段階ではログイン確認が終了しているはずなのに、無いという状況
          resolve(false);
          return;
        }

        //ユーザーの科目collectionを取得する
        this.utils_get_subCollections('users', uid, kamokuName).then((obj) => {
          obj = JSON.parse(JSON.stringify(obj));
          if (Object.keys(obj).length != 0) {
            this.$store.commit('update_store', {
              path: 'user.fs',
              key: kamokuName,
              data: obj,
            });
            resolve(true);
            return;
          }

          //FSにオブジェクトが無いので新規作成する
          const obj_kamoku = {
            activityLogs: {},
            qState: {},
            stateStatistics: {},
          };
          this.utils_merge_userFS(kamokuName, obj_kamoku).then(() => {
            alert(
              '学習を記録する準備ができました！\n問題演習をはじめましょう🔥',
            );
            this.prepare_userKamokuCollection(kamokuName).then(() => {
              //FSに作成した初期データをロードしてから解決する
              resolve(true);
              return;
            });
          });
        });
      });
    },
    //progressとbufferの値を集計して格納する
    insertUserData(kamokuName) {
      const sectionObj = this.$store.state[kamokuName].sectionObj;
      const q = this.$store.state[kamokuName].questions.questions;
      const arrayObj_q = this.utils_create_arrayObjFromObj(q);
      const obj_qState = this.user.fs[kamokuName].qState;

      if (obj_qState == undefined) {
        return;
      }

      //storeに参照代入しています
      Object.keys(sectionObj).forEach((tangenId) => {
        const sections = sectionObj[tangenId].sections;
        Object.keys(sections).forEach((sectionId) => {
          //データに状態情報を格納します
          this.get_userQestionState(
            sections,
            sectionId,
            arrayObj_q,
            obj_qState,
          );
        });
      });
    },
    get_userQestionState(sections, sectionId, arrayObj_q, obj_qState) {
      const sectionQuestions = arrayObj_q.filter(
        //private以外（publicと旧データ（undifined）を集計する）
        (data) => data.sectionId == sectionId && data.status != 'private',
      );

      const arr_qId = sectionQuestions.map((ele) => ele.id);
      const stateObj = this.utils_deepCopy(this.$store.state.stateObj);
      arr_qId.forEach((qId) => {
        const qObj = obj_qState.data[qId];
        //解いていない問題はqStateのデータが無い
        if (qObj == undefined) {
          return;
        }
        const state = qObj.currentState;
        stateObj[state]++;
      });

      const cc = Number(stateObj.correctConstant);
      const co = Number(stateObj.correctOnce);
      const ccoc = cc + co;

      //未回答・不正解は、データ無しの場合を考慮して差額で算出する。
      const unin = arr_qId.length - ccoc;
      let text_amount = `${cc} / ${co} / ${unin}`;
      if (arr_qId.length === 0) {
        text_amount = '作問中…';
      }

      //未回答は、、データ無しの場合を考慮して差額で算出する。
      const un = arr_qId.length - ccoc - Number(stateObj.incorrect);
      const sum = arr_qId.length;

      //progressは連続正解状態の問題%
      const perProgress = Math.round((cc / sum) * 100);

      //buffer長さは連続正解と正解の問題%になる
      const amountBuffer = ccoc;
      const perBuffer = Math.round((amountBuffer / sum) * 100);

      sections[sectionId].progress = perProgress;
      sections[sectionId].buffer = perBuffer;
      sections[sectionId].text_amount = text_amount;
      sections[sectionId].unanswered = un;
      sections[sectionId].incorrect = stateObj.incorrect;
      sections[sectionId].correctOnce = stateObj.correctOnce;
      sections[sectionId].correctConstant = stateObj.correctConstant;
    },
    gf_get_kamokuType(kamokuName) {
      return this.conf.itemsKamoku[kamokuName].kamoku_type;
    },

    //drawerのリスト制御
    //========================================================================
    gf_switchDrawerList(listName) {
      const obj_list = this.webApp.drawer.list;
      Object.keys(obj_list).forEach((key) => {
        let value = false;
        if (key == listName) {
          value = true;
        }
        this.$store.commit('update_store', {
          path: 'webApp.drawer.list',
          key: key,
          data: value,
        });
      });
    },

    //結果表示関連、主にstudyLogsで使用している
    //========================================================================
    gf_getActivityTimeFS(saveData) {
      const startTime = Number(saveData.startTime);
      const endTime = Number(saveData.endTime);
      const sec = Math.round((endTime - startTime) / 1000);
      return this.utils_formatSecond(sec);
    },
    gf_getCorrectAmountAndPer(saveData) {
      const answerData = this.utils_create_arrayObjFromObj(saveData.answerData);
      const amount = answerData.length;
      const correctAmount = answerData.filter(
        (data) => data.isCorrect == true,
      ).length;
      const correctPer = Math.round((correctAmount / amount) * 100);
      return { amount: correctAmount + '/' + amount, correctPer: correctPer };
    },
    //現在のstateの統計を取得する
    gf_getStateStatistics(kamokuName) {
      const qState = this.user.fs[kamokuName].qState.data;
      const stateObj = this.utils_deepCopy(this.$store.state.stateObj);
      Object.keys(qState).forEach((qid) => {
        const currentState = qState[qid].currentState;
        stateObj[currentState] = Number(stateObj[currentState]) + 1;
      });
      return stateObj; //{unanswered:0, incorrect:0, correctOnce:0, correctConstant:0}
    },
    //データ移行措置、全科目が移行したら再度コーディングする
    //========================================================================
    gf_isNewType(kamokuName) {
      const itemsKamoku = this.$store.state.conf.itemsKamoku;
      const item = itemsKamoku[kamokuName];
      return item.isNewType;
    },
  },
};
