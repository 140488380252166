<template>
  <v-container v-show="isPageComplete">
    <center>
      <!-- ログインカード -->
      <v-card width="95%" max-width="500px" style="margin-bottom: 50px">
        <v-system-bar color="orange darken-1" />
        <v-card-title>
          <v-row justify="center" style="margin: 10px auto">
            <h4>ウズカレテストにログイン</h4>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div style="max-width: 300px">
            <!--Google-->
            <v-row justify="center" style="margin: 0px">
              <v-btn
                width="90%"
                outlined
                rounded
                style="text-transform: none"
                @click="login({ method: 'google' })"
              >
                <v-icon left>mdi-google</v-icon><b>Google でログイン/登録</b>
              </v-btn>
            </v-row>
            <!--または-->
            <v-row justify="center" style="margin: 20px 20px 0px">
              <v-divider style="margin-top: 11px" />
              &emsp;または&emsp;
              <v-divider style="margin-top: 11px" />
            </v-row>
            <!--Mail-->
            <v-row justify="center" style="margin: 0px">
              <v-form
                @submit.prevent="login({ method: 'email' })"
                style="width: 100%"
              >
                <v-text-field
                  v-model="email"
                  label="メールアドレス"
                  style="margin: 0px 20px"
                />
                <v-text-field
                  v-model="password"
                  label="パスワード"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  @click:append="showPass = !showPass"
                  hint="半角英数字6文字以上"
                  style="margin: 0px 20px"
                />
                <v-btn width="90%" outlined rounded type="submit">
                  <v-icon left>mdi-email-outline</v-icon>
                  <b>メール でログイン</b>
                </v-btn>
              </v-form>
            </v-row>
            <v-row style="margin: 15px 20px">
              メールで登録する場合は
              <a @click="createDialog = true"><b>コチラ</b></a>
              <div style="margin: 15px auto"></div>
              パスワードを忘れた場合は
              <a @click="resetDialog = true"><b>コチラ</b></a>
            </v-row>
          </div>
          <div style="margin-top: 30px"></div>
          <PrivacyPlicy />
          <!--または-->
          <v-row style="margin: 30px 20px 10px">
            登録せずに利用する場合は
            <a @click="loginAsFreeUser()"><b>コチラ</b></a>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- アカウント作成ダイアログ -->
      <v-dialog v-model="createDialog" persistent max-width="500px">
        <v-card>
          <div style="margin: auto 5px; float: right">
            <a><v-icon @click="createDialog = false">mdi-close</v-icon></a>
          </div>
          <v-card-title>アカウントの作成</v-card-title>
          <v-card-text>
            <center>
              <div style="max-width: 300px">
                <v-row justify="center" style="margin: 10px auto">
                  <v-text-field
                    v-model="email"
                    label="メールアドレス"
                  ></v-text-field>
                </v-row>
                <v-row justify="center" style="margin: 10px auto">
                  <v-text-field
                    v-model="password"
                    label="パスワード"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                    hint="半角英数字6文字以上"
                    persistent-hint
                  ></v-text-field>
                  <v-btn
                    width="90%"
                    outlined
                    rounded
                    @click="createUser()"
                    style="margin-top: 30px"
                  >
                    <b>アカウントを作成する</b>
                  </v-btn>
                </v-row>
              </div>
            </center>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- パスワード再設定ダイアログ -->
      <v-dialog v-model="resetDialog" persistent max-width="500px">
        <v-card>
          <div style="margin: auto 5px; float: right">
            <a><v-icon @click="resetDialog = false">mdi-close</v-icon></a>
          </div>
          <v-card-title>パスワードの再設定</v-card-title>
          <v-card-text>
            <center>
              <div style="max-width: 300px">
                <v-row justify="center" style="margin: 10px auto">
                  <v-text-field
                    v-model="email"
                    label="メールアドレス"
                  ></v-text-field>
                  <v-btn
                    width="90%"
                    outlined
                    rounded
                    @click="resetPassWithEmail()"
                    style="margin-top: 10px"
                  >
                    <b
                      ><v-icon>mdi-email-send-outline</v-icon>
                      再設定メールを送る</b
                    >
                  </v-btn>
                </v-row>
                <v-row justify="center" style="margin: 20px auto">
                  <div v-if="msg.flag == true">
                    <font color="blue">{{ msg.str }}</font>
                  </div>
                  <div v-else>
                    <font color="red">{{ msg.str }}</font>
                  </div>
                </v-row>
              </div>
            </center>
          </v-card-text>
        </v-card>
      </v-dialog>
    </center>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import PrivacyPlicy from '../compGeneral/PrivacyPolicy.vue';
export default {
  name: 'Root',
  components: { PrivacyPlicy },
  computed: {
    ...mapState(['app']),
  },
  data() {
    return {
      isPageComplete: false, //処理がすべて終わったらtrueにして要素を表示
      email: '',
      password: '',
      showPass: false, //パスワード入力の表示切り替え
      createDialog: false, //アカウント登録のダイアログ
      resetDialog: false, //パスワード再設定のダイアログ
      msg: {
        str: ' ', //メール送信後のメッセージ
        flag: false, //trueなら青文字、falseなら赤文字
      },
    };
  },
  async mounted() {
    this.$store.commit('loading', true);
    await this.gf_keepActive();
    await this.gf_keepLogin();

    this.$store.commit('mainHeader', true);
    this.$store.commit('hamburger', false);
    this.isPageComplete = true;
    this.$store.commit('loading', false);
  },
  methods: {
    //======================================================================================
    async login(obj) {
      this.$store.commit('loading', true);

      //すでにログインしていればhomeへ送る
      if (this.gf_isLogin()) {
        this.$router.push('/home');
        return;
      }

      //ログイン方法の確認
      if (obj.method == 'google') {
        obj.method = 'signInWithPopup';
      } else if (obj.method == 'email') {
        obj.method = 'signInWithEmailAndPassword';
        const vali = this.validation(); //メールのバリデーション
        if (vali) {
          obj.email = this.email;
          obj.password = this.password;
        } else {
          this.$store.commit('loading', false);
          return;
        }
      }
      await this.gf_login_uzcolletest(obj).then((res) => {
        if (res) {
          this.$router.push('/home');
        } else {
          alert('ログインに失敗しました。');
          return;
        }
      });
      this.$store.commit('loading', false); //SNS認証ダイアログをクローズした時用に必要
    },
    validation() {
      const password = this.password;
      if (!password.match(/^[A-Za-z0-9]+$/)) {
        alert('パスワードが半角英数字になっていません');
        return false;
      }
      if (password.length < 6) {
        alert('パスワードが6文字以上になっていません');
        return false;
      }
      if (this.email == '') {
        alert('メールアドレスが入力されていません');
        return false;
      }
      return true;
    },
    async createUser() {
      const vali = await this.validation();
      this.$store.commit('loading', true);
      const obj = {};
      if (vali) {
        obj.email = this.email;
        obj.password = this.password;
      } else {
        this.$store.commit('loading', false);
        return;
      }

      await this.gf_create_uzcolletest(obj).then((res) => {
        this.createDialog = false;
        this.$store.commit('loading', false);
        if (res) {
          this.$router.push('/home');
        } else {
          alert('アカウント作成に失敗しました。');
          return;
        }
      });
    },
    async resetPassWithEmail() {
      if (this.email == '') {
        alert('メールアドレスが入力されていません');
        return;
      }
      this.$store.commit('loading', true);
      const res = await this.gf_resetPassWithEmail(this.email);
      if (res.flag) {
        this.msg.str = 'メールの送信が完了しました！';
        this.msg.flag = true;
      } else {
        this.msg.str = res.result;
        this.msg.flag = false;
      }
      this.$store.commit('loading', false);
    },
    loginAsFreeUser() {
      const res = window.confirm(
        'ゲストモードで利用します。\n学習記録などの機能が使えませんがよろしいですか？',
      );
      if (res) {
        this.gf_signOut_uzcolletest().then(() => {
          this.$router.push('/home');
        });
      } else {
        return;
      }
    },
  },
};
</script>
