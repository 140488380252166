import Vue from 'vue';
import VueRouter from 'vue-router';
import Root from '../views/Root.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', name: 'root', component: Root },
  { path: '/home', name: 'Home', component: () => import('../views/Home.vue') },
  {
    path: '/myAcount',
    name: 'MyAcount',
    component: () => import('../compGeneral/MyAcount.vue'),
  },
  {
    path: '/itPassport',
    name: 'itPassport',
    component: () => import('../compKamoku/itPassport.vue'),
  },
  {
    path: '/ccna',
    name: 'CCNA',
    component: () => import('../compKamoku/ccna.vue'),
  },
  {
    path: '/ciscoCommand',
    name: 'CiscoCommand',
    component: () => import('../compKamoku/ciscoCommand.vue'),
  },
  {
    path: '/linuc101',
    name: 'Linuc101',
    component: () => import('../compKamoku/linuc101.vue'),
  },
  {
    path: '/linuc101Command',
    name: 'Linuc101Command',
    component: () => import('../compKamoku/linuc101Command.vue'),
  },
  {
    path: '/linuc102',
    name: 'Linuc102',
    component: () => import('../compKamoku/linuc102.vue'),
  },
  {
    path: '/linuc102Command',
    name: 'Linuc102Command',
    component: () => import('../compKamoku/linuc102Command.vue'),
  },
  { path: '/quiz', name: 'Quiz', component: () => import('../views/Quiz.vue') },
  {
    path: '/quizPage',
    name: 'QuizPage',
    component: () => import('../views/QuizPage.vue'),
  },
  {
    path: '/quizCommand',
    name: 'QuizCommand',
    component: () => import('../views/QuizCommand.vue'),
  },
  {
    path: '/ccnaBasic',
    name: 'ccnaBasic',
    component: () => import('../compKamoku/ccnaBasic.vue'),
  },
  {
    path: '/javaSilver',
    name: 'javaSilver',
    component: () => import('../compKamoku/javaSilver.vue'),
  },
  {
    path: '/ccnaBasic2',
    name: 'ccnaBasic2',
    component: () => import('../compKamoku/ccnaBasic2.vue'),
  },
  {
    path: '/ccna2',
    name: 'ccna2',
    component: () => import('../compKamoku/ccna2.vue'),
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('../views/Result.vue'),
  },
  {
    path: '/showQuestion',
    name: 'ShowQuestion',
    component: () => import('../views/ShowQuestion.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
