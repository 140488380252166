<template>
  <v-app>
    <v-main>
      <!-- ヘッダー 問題演習時には非表示 -->
      <v-app-bar
        app
        :color="isLogin ? 'orange darken-1' : 'grey lighten-1'"
        dark
        dense
        v-if="webApp.showMainHeader"
      >
        <v-app-bar-nav-icon
          @click="switchDrawer()"
          v-show="webApp.drawer.hamburger"
        ></v-app-bar-nav-icon>
        <v-toolbar-title style="margin-left: -10px">
          <a @click="gf_moveToTop()">
            <img
              src="@/assets/log_black.png"
              height="25px"
              width="100%"
              style="margin-top: 10px"
            />
          </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <span v-show="gf_isLogin()" style="color: white">{{ email }}</span>
      </v-app-bar>
      <router-view />

      <!-- ローディングダイアログ -->
      <center>
        <v-dialog
          v-model="isLoading"
          overlay-color="white"
          persistent
          width="300"
        >
          <v-card color="orange" dark>
            <v-card-text>
              now loading . . .
              <v-progress-linear
                indeterminate
                color="white"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </center>
    </v-main>
  </v-app>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'App',
  data() {
    return {};
  },
  computed: {
    ...mapState(['user', 'webApp']),
    email() {
      const email = this.user.auth.email;
      if (email != undefined) {
        return email.substr(0, 10) + '…';
      } else {
        return '';
      }
    },
    isLogin() {
      const email = this.user.auth.email;
      return !!email;
    },
    isLoading() {
      return this.webApp.isLoading;
    },
  },
  methods: {
    switchDrawer() {
      const isShow = this.webApp.drawer.isShow;
      this.$store.commit('update_store', {
        path: 'webApp.drawer',
        key: 'isShow',
        data: !isShow,
      });
    },
  },
};
</script>
