import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //WEBアプリ一般の制御
    webApp: {
      isActive: false, //リロード検知に利用する
      showMainHeader: true, //メインヘッダー、問題ページや結果ページなどでOFF
      drawer: {
        isShow: false,
        hamburger: true,
        list: {
          lecture: false,
          studyLogs: false,
          assignment: false,
          search: false,
          questions: true,
        },
        listItemColor: 'grey darken-4',
      },
      allowLeavePage: false, //ページ離脱の許可（検知フラグ）
      isLoading: false, //ローディングlinerの制御
    },
    //gf_keepActiveにてfirestoreから設定情報（conf）を格納する
    conf: {},
    //科目データを保管する
    kamoku: {},
    /*データ構造
      {
        isLoad:boolean,
        kamokuDefault:{}, => firestore/publicData/conf/kamokuDefaultからdeepCopy
        questions:{ sectionData, questions, etc }, => firestore/public/{{kamoku}}
      },
    */

    //ログインしたユーザーのデータを格納する。
    user: {
      auth: {},
      claims: {},
      fs: {},
      assignment: {},
    },

    //--------------ここから。初期値データ
    kamokuDefault: {
      isLoad: false,
      lecture: false,
      questions: {},
      sectionObj: [], //homeでsectionListから作成
      option: {
        selMode: { text: '練習', value: 'practice' }, //モード
        selHint: { text: 'あり', value: true }, //出題ヒント
        selQuestionState: [
          //問題状態の選択
          { stateId: 0, text: '未回答', value: 'unanswered' },
          { stateId: 1, text: '✕不正解', value: 'incorrect' },
        ],
        selAmountQ: 'all', //出題数
      },
    },
    items: {
      amountCheckedQ: 0,
      itemsAmountQ: [
        'all',
        1,
        5,
        10,
        15,
        20,
        25,
        30,
        40,
        50,
        60,
        70,
        80,
        90,
        100,
        120,
        140,
        160,
        180,
        200,
      ],
      itemsHint: [
        { text: 'あり', value: true },
        { text: 'なし', value: false },
      ],
      itemsMode: [
        { text: '練習', value: 'practice' },
        { text: 'テスト', value: 'test' },
      ],
      itemsQuestionsState: [
        { stateId: 0, text: '未回答', value: 'unanswered' },
        { stateId: 1, text: '✕不正解', value: 'incorrect' },
        { stateId: 2, text: '〇正解', value: 'correctOnce' },
        { stateId: 3, text: '◎連続正解', value: 'correctConstant' },
      ],
    },
    stateObj: {
      unanswered: 0,
      incorrect: 0,
      correctOnce: 0,
      correctConstant: 0,
    },
    //--------------ここまで

    //--------------ここから。問題演習で使用しているデータ。mutationsを経由せずに直接出し入れしているデータもあります。
    saveData: {
      //保存用データ
      selKamokuName: '', //問題演習をする科目
      selSectionId: {}, //出題するセクションのID
      /*selSectionId{
                101:{tangenId:100, sectionId:101},
                302:{tangenId:300, sectionId:302},
                701:{tangenId:700, sectionId:701},
            }*/
      answerData: {}, //1問ごとの出題データを格納していく
      /*answerDataのデータ構造{
                qNum : { qNum:qNum, sectionId:sectionId, isCorrect:isTrue, ansData:this.Cont },
                qNum : { qNum:qNum, sectionId:sectionId, isCorrect:isTrue, ansData:this.Cont },
            }*/
      startTime: '',
      endTime: '',
      option: {}, //出題オプション
    },
    //出題管理用データ、演習中すっと保持する
    perm: {
      questionData: [], //出題する問題のデータ
      qNum: 0, //問題番号、Contに置くと毎回初期化されてしまう。
      isCommand: false, //コマンド問題か否か、QuestionのkamokuStartで設定される
      isAssignment: false, //課題の取組か否か
      assignmentId: '', //課題Id、保存する時に必要
    },
    //出題管理用データ、問題ごとに初期化される
    cont: {
      // ----------以下のデータはcontQ_saveAndNextで格納しています。firestoreに保存する部分。
      questionId: '', //表示用、問題ID
      startQTime: '', //1問を開始した時刻
      endQTime: '', //1問を終了した時刻

      // ----------問題データは、上記のデータと合わせて、showQuestionで必要。
      qOriginal: '', //問題レコードのオリジナルデータ

      // ----------ココ以下は出題では使用するが、saveDataには格納しない。（showQuestionはできる）
      question: '', //問題文の表示
      qOnlyStr: '', //タグ無しのテキスト問題文のみを保管する。結果の一覧表示で使用する。
      unFilteredCorrect_forCheckBox: [], //出題数で絞り込む前の正解データ（全部）
      unFilteredDummy_forCheckBox: [], //出題数で絞り込む前のダミーデータ（全部）
      correctData: [], //正答データ
      /*データ構造
        [
            { randId:rand, checked: false, color: "", text: text, value: value }, //permutation:1
            { randId:rand, checked: false, color: "", text: text, value: value },
            { randId:rand, checked: false, color: "", text: text, value: value },
        ],
      */

      //command問題専用
      //------------------------------------------
      questionC: '', //問題のコマンド
      questionS: '', //問題文
      input: '', //ユーザーの入力
      vifWarning: false,
      inputCommand: '', //ユーザが入力したコマンド（不正解の時に表示）
      message: '', //不正解の理由を示すメッセージ
      vifAns: false, //正答の表示
      answer: '', //正答
      colorNextBtn: '', //次へボタン
      tempCandi: [], //解答候補のコマンドを一時保管しておく
      isJudged: false,
      vifCom: false, //解説の表示
      comment: '', //解説
    },
    contBtn: {
      //出題ボタンの管理 => saveDataにすべて格納する
      disBtnShow: false, //解答ボタン
      disBtnIncorrect: true, //不正解ボタン
      disBtnCorrect: true, //正解ボタン
      btnAllUncheck: false, //チェックボックスの全件解除ボタン

      //チェックボックス制御
      numberOfSelect: 0, //数字がセットされていれば「4つ選択」、0なら「全て選択」などと表示する
      checkBoxItems: [], //このデータを選択肢として表示する。＠＠＠＠直接操作可にしています...
      disCheckBox: false, //解答表示後にチェックボックスの操作を無効にする
      showCheckBox: false, //チェックボックスコンポーネントの表示
      selCheckBoxItems: [], //＠＠＠＠ユーザが選んだ選択肢のデータ

      //表示関連
      showAnswerAndComment: false, //解答解説の表示を制御
    },
    //--------------ここまで
  },
  mutations: {
    //--------------------------------------------------------------------------------------チーム開発用にここから新関数として抽象化する
    /*update_storeに関する原因不明の挙動
      default.optionからdeepCopyした「kamoku.option」に以下のようにoptionを代入しようとした
      "setVuex", {path:this.kamokuName, key:"option", data:newOption}
      オブジェクトは通常通り上書きされたが、その後setVuexがまた実行され、意図しないkeyに意図しない値がセットされてしまった。
      値のset、空状態のオブジェクトにsetはしたことがあったが、マージする形は初めてだった。
      オブジェクトをマージする形だと不具合がおこるのか？？
    */
    update_store(state, obj) {
      const path = obj.path;
      const data = obj.data;
      const key = obj.key;
      const arrPath = path.split('.');
      if (path == 'state') {
        state[key] = data;
        return;
      }

      let result = state;
      for (let i = 0; i <= arrPath.length - 1; i++) {
        //path通りにオブジェクト有無を調べて、無ければその都度作成する
        if (result[arrPath[i]] == undefined) {
          //もしオブジェクトでなかったり、path指定が間違っていれば、新たに{}を作成する。
          result[arrPath[i]] = {};
        }
        result = result[arrPath[i]]; //resultにオブジェクトを入れ直す。=>ネストを掘り下げていく。
      }
      result[key] = data;
      return true;
    },
    //ローディングダイアログの制御
    loading(state, isLoad) {
      const obj = {
        path: 'webApp',
        key: 'isLoading',
        data: isLoad,
      };
      this.commit('update_store', obj);
    },
    leavePage(state, boolean) {
      const obj = {
        path: 'webApp',
        key: 'allowLeavePage',
        data: boolean,
      };
      this.commit('update_store', obj);
    },
    mainHeader(state, boolean) {
      const obj = {
        path: 'webApp',
        key: 'showMainHeader',
        data: boolean,
      };
      this.commit('update_store', obj);
    },
    //ハンバーガーメニューの制御
    hamburger(state, isShow) {
      this.commit('update_store', {
        path: 'webApp.drawer',
        key: 'hamburger',
        data: isShow,
      });
    },
    //boolean、2以上ネストダメ！{path:saveData}、参照渡しなのでcommitなしになってます…
    initializeState(state, obj) {
      const target = this.getters.getState(obj.path);
      Object.keys(target).forEach((key) => {
        if (typeof target[key] == 'boolean') {
          target[key] = false;
        }
        if (typeof target[key] == 'string') {
          target[key] = '';
        }
        if (typeof target[key] == 'object') {
          if (Array.isArray(target[key])) {
            target[key] = [];
          } else {
            target[key] = {};
          }
        }
        if (typeof target[key] == 'number') {
          target[key] = 0;
        }
      });
    },
    //演習問題のコントロールボタンを初期化する
    initContBtn() {
      const target = this.state.contBtn;
      Object.keys(target).forEach((key) => {
        if (typeof target[key] == 'boolean') {
          if (key == 'disBtnIncorrect' || key == 'disBtnCorrect') {
            //デフォルトでdisable:trueがあるから注意！
            target[key] = true;
          } else {
            target[key] = false;
          }
        }
        if (typeof target[key] == 'string') {
          target[key] = '';
        }
        if (typeof target[key] == 'object') {
          if (Array.isArray(target[key])) {
            target[key] = [];
          } else {
            target[key] = {};
          }
        }
        if (typeof target[key] == 'number') {
          target[key] = 0;
        }
      });
    },
    //【旧データ用】単元内のセクションを全部選択する
    checkAll(state, obj) {
      //全件選択, { path:"ccna.sectionObj", tangenId:tangenId }
      const path_sectionObj = obj.path;
      const tangenId = obj.tangenId;
      const sectionObj = this.getters.getState(path_sectionObj);
      const isCheck = sectionObj[tangenId]['checked'];
      for (var i = 1; i <= 15; i++) {
        //※セクション数はmax15くらいだと仮定
        const sectionId = Number(tangenId) + i;
        const sectionData = sectionObj[tangenId]['sections'][sectionId];
        if (sectionData) {
          const path =
            path_sectionObj +
            '.' +
            tangenId +
            '.' +
            'sections' +
            '.' +
            sectionId;
          this.commit('update_store', {
            path: path,
            key: 'checked',
            data: isCheck,
          });
        }
      }
    },
    //【新データ用】単元内のセクションを全部選択する
    checkAllSections(state, obj) {
      //全件選択, { path:"ccna.sectionObj", tangenId:tangenId }
      const path = obj.path;
      const tangenId = obj.tangenId;
      const sectionObj = this.getters.getState(path);
      const isChecked = sectionObj[tangenId]['checked'];

      const obj_sections = sectionObj[tangenId].sections;
      Object.keys(obj_sections).forEach((sectionId) => {
        const state_path = `${path}.${tangenId}.sections.${sectionId}`;
        this.commit('update_store', {
          path: state_path,
          key: 'checked',
          data: isChecked,
        });
      });
    },
  },
  getters: {
    //指定パスのデータを返す、initializeStateで唯一使用している...
    getState: (state) => (strPath) => {
      const arrPath = strPath.split('.');
      function isObject(item) {
        return (
          typeof item === 'object' && item !== null && !Array.isArray(item)
        );
      }
      let result = state;
      for (let i = 0; i <= arrPath.length - 2; i += 1) {
        if (!isObject(result[arrPath[i]])) {
          result[arrPath[i]] = {};
        }
        result = result[arrPath[i]];
      }
      return result[arrPath[arrPath.length - 1]];
    },
  },
});
