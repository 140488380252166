import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['user', 'saveData', 'perm', 'cont', 'contBtn']),
    option() {
      return this.saveData.option;
    },
  },
  methods: {
    //選択式の作問
    //======================================================
    //saveDataの初期化
    //------------------------------------------------------
    async contQ_init(obj) {
      //Questionsページにて、遷移直前に実行
      const selSectionId = obj.selSectionId;
      const kamokuName = obj.kamokuName;

      //stateのsaveDataを初期化
      this.$store.commit('initializeState', { path: 'saveData' });

      //permanentを初期化
      this.$store.commit('initializeState', { path: 'perm' });

      //演習問題管理用saveDataに、選択された単元を保管
      this.$store.commit('update_store', {
        path: 'saveData',
        key: 'selSectionId',
        data: selSectionId,
      });
      const option = this.$store.state[kamokuName].option;

      //演習問題管理用saveDataに、選択されたoptionを保管
      this.$store.commit('update_store', {
        path: 'saveData',
        key: 'option',
        data: option,
      });

      await this.$store.commit('update_store', {
        path: 'saveData',
        key: 'selKamokuName',
        data: kamokuName,
      });
    },
    contQ_createMain() {
      const kamokuName = this.saveData.selKamokuName;
      const q = this.$store.state[kamokuName].questions.questions;
      let questions = this.utils_create_arrayObjFromObj(q);

      if (this.gf_isNewType(kamokuName)) {
        questions = questions.filter((data) => data.status === 'public');
      }

      const selSectionId = this.saveData.selSectionId;
      var arrayObj_sectionQ = [];
      Object.keys(selSectionId).forEach((key) => {
        //問題データから選択されたセクションデータを絞り込む
        const sectionId = selSectionId[key]['sectionId'];
        const arr = questions.filter((data) => data['sectionId'] == sectionId);
        arrayObj_sectionQ.push(...arr);
      });

      //selQuestionsStateをもとに、問題を絞り込み、currentStateを統合
      if (this.gf_isLogin()) {
        arrayObj_sectionQ = this.filterQuestionsByState(arrayObj_sectionQ);
      }

      const amount = this.getAmount(arrayObj_sectionQ);
      arrayObj_sectionQ = this.shuffleArray(arrayObj_sectionQ);
      const resultData = this.extractEquallyBySection(
        arrayObj_sectionQ,
        amount,
      );
      this.$store.commit('update_store', {
        path: 'perm',
        key: 'questionData',
        data: resultData,
      });
      return true;
    },
    //問題作成
    //------------------------------------------------------
    //選択された問題状態に絞り込む
    filterQuestionsByState(sectionQData) {
      //状態別での絞り込みと、currentStateの統合
      let selQState = this.option.selQuestionState; //選択されているstateを配列に整理する

      if (selQState === undefined) {
        selQState = this.option.selQuestionsState; //移行措置
      }

      const arrayObj_selQState = this.utils_create_arrayObjFromObj(selQState);
      const kamokuName = this.saveData.selKamokuName;
      const obj_qState = this.user.fs[kamokuName].qState.data;

      //問題データからIDを取り出して、ユーザデータで検索
      sectionQData.forEach((ele) => {
        const id = ele.id;
        let obj = obj_qState[id];
        if (obj == undefined) {
          obj = {
            currentState: 'unanswered',
            logs: {},
          };
        }
        ele['qState'] = obj; //currentStateがある場合、問題データに統合
      });

      var filterdQ = [];
      arrayObj_selQState.forEach((ele) => {
        const state = ele.value;
        const arrayObj_q = sectionQData.filter(
          (data) => data.qState.currentState == state,
        );
        filterdQ.push(...arrayObj_q);
      });
      return filterdQ;
    },
    //出題数の取得
    getAmount(questionsData) {
      const selAmountQ = this.option.selAmountQ;
      if (selAmountQ == 'all') {
        var amount = questionsData.length;
      } else {
        if (selAmountQ >= questionsData.length) {
          amount = questionsData.length;
        } else {
          amount = selAmountQ;
        }
      }
      return amount;
    },
    //シャッフルする
    shuffleArray(arr) {
      var i = arr.length;
      while (--i) {
        var j = Math.floor(Math.random() * (i + 1));
        if (i == j) continue;
        var k = arr[i];
        arr[i] = arr[j];
        arr[j] = k;
      }
      return arr;
    },
    //セクションごとでの出題数が均等になるようにする
    extractEquallyBySection(questionData, amountQ) {
      const selSectionId = this.saveData.selSectionId;
      /*selSectionId{
                101:{tangenId:100, sectionId:101},
                302:{tangenId:300, sectionId:302},
            }*/
      const arSectionId = Object.keys(selSectionId);
      var ind_sectionId_col = 0;
      var ind_row = 0;
      var result = [];
      while (result.length < amountQ) {
        const sectionId = arSectionId[ind_sectionId_col];
        const arr = questionData.filter(
          (data) => data['sectionId'] == sectionId,
        );
        if (ind_sectionId_col > arSectionId.length - 1) {
          //最後のセクションIDに来たら、最初に戻る
          ind_sectionId_col = 0;
          ind_row++; //抽出する階層を1つ進める
          continue;
        }
        const data = arr[ind_row];
        if (data != undefined) {
          result.push(data);
          ind_sectionId_col++; //データを格納できたら、次のセクションIDへ
        } else {
          ind_sectionId_col++; //データが無ければ、何もせず次のセクションIDへ
        }
      }
      return result;
    },

    //出題の制御
    //======================================================
    //検索表示の関係で、関数を切り分けている
    contQ_setQuestionMain() {
      //レコードの取得
      const Q = this.perm.questionData;
      const ind = this.perm.qNum;
      const ele = Q[ind];
      this.contQ_initCont();
      this.contQ_create(ele);

      //1問の開始時間を記録
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'startQTime',
        data: new Date().getTime(),
      });
    },
    //contの初期化は、setQuestionで毎回実行
    contQ_initCont() {
      this.$store.commit('initializeState', { path: 'cont' });
      this.$store.commit('initContBtn');
    },
    contQ_create(ele) {
      //作成、1問の開始時間を保存
      //オリジナルデータの保存
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'qOriginal',
        data: ele,
      });

      // 検索からshowQuestionを開く場合this.saveData.selKamokuNameには値が入っていないのでthis.selKamokuNameから値を取ってくる
      const kamoku = this.saveData.selKamokuName || this.selKamokuName;

      // TODO:新データへの移行が完了したら以下のif文ロジックは削除して、this.createDataForNewData(ele)とする。
      if (this.gf_isNewType(kamoku)) {
        this.createDataForNewData(ele);
      } else {
        this.createData(ele);
      }
      this.contBtn.showCheckBox = true; //チェックボックスの表示
    },
    //データ作成
    //======================================================
    createData(ele) {
      this.setQuestionSentence(ele); //図表と問題文を統合させてセットする
      this.setForCheckBox(ele, 'answer', 'unFilteredCorrect_forCheckBox'); //answerのチェックボックス用データを作成
      this.setForCheckBox(ele, 'dummy', 'unFilteredDummy_forCheckBox'); //dummyのチェックボックス用データを作成
      const cor = this.cont['unFilteredCorrect_forCheckBox'];
      const dum = this.cont['unFilteredDummy_forCheckBox'];

      const obj = this.getNumberOfChoices(cor, dum, ele); //correctとdummyの出題数を決定する。
      const amountCorrect = obj.correct;
      const amountDummy = obj.dummy;

      const correct = this.getCorrectData(cor, amountCorrect);

      //正解データの保持
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'correctData',
        data: correct,
      });
      var newCorrect = correct.slice(); //これからシャッフルするので値渡し（参照渡し不可）でコピーする
      newCorrect = this.shuffle(newCorrect);

      //決定された出題数に従い、シャッフル済みの、ダミーの新しい配列を作成
      const newDummy = this.getNewArrayByRandomSampling(dum, amountDummy);

      //選択肢を生成し、シャッフルして、セットする
      this.setCheckBoxItems(newCorrect, newDummy);

      //解答すべき選択肢数の表示を制御する
      this.setNumberOfSelect();

      this.$store.commit('update_store', {
        path: 'cont',
        key: 'questionId',
        data: ele['id'],
      });
    },
    createDataForNewData(ele) {
      this.setQuestionSentence(ele); //図表と問題文を統合させてセットする
      this.setForCheckBoxForNewData(
        ele,
        'obj_answer',
        'unFilteredCorrect_forCheckBox',
      ); //answerのチェックボックス用データを作成
      this.setForCheckBoxForNewData(
        ele,
        'obj_dummy',
        'unFilteredDummy_forCheckBox',
      ); //dummyのチェックボックス用データを作成
      const cor = this.cont['unFilteredCorrect_forCheckBox'];
      const dum = this.cont['unFilteredDummy_forCheckBox'];

      const obj = this.getNumberOfChoices(cor, dum, ele); //correctとdummyの出題数を決定する。
      const amountCorrect = obj.correct;
      const amountDummy = obj.dummy;

      const correct = this.getCorrectData(cor, amountCorrect);

      //正解データの保持
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'correctData',
        data: correct,
      });
      var newCorrect = correct.slice(); //これからシャッフルするので値渡し（参照渡し不可）でコピーする
      newCorrect = this.shuffle(newCorrect);

      //決定された出題数に従い、シャッフル済みの、ダミーの新しい配列を作成
      const newDummy = this.getNewArrayByRandomSampling(dum, amountDummy);

      //選択肢を生成し、シャッフルして、セットする
      this.setCheckBoxItems(newCorrect, newDummy);

      //解答すべき選択肢数の表示を制御する
      this.setNumberOfSelect();

      this.$store.commit('update_store', {
        path: 'cont',
        key: 'questionId',
        data: ele['id'],
      });
    },
    setQuestionSentence(ele) {
      //図表と問題文を統合させてセットする。
      var content = ele.question;
      if (ele.tag == '' || ele.tag == undefined) {
        //処理なし
      } else {
        const iframeTag = this.contQ_insertClassTagForIframe(ele.tag); //iframeタグにクラスを挿入
        content = iframeTag + '<br>' + ele['question'];
      }
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'question',
        data: content,
      });
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'qOnlyStr',
        data: ele['question'],
      }); //タグ無しのテキスト問題文のみを保管する。結果の一覧表示で使用する。
    },
    contQ_insertClassTagForIframe(str) {
      //iframeの前後にクラスタグを挿入します。コマンド問題でも単体で使用しています。
      const replace1 =
        '<center><div class="slide-base"><div class="slide"><iframe';
      const replace2 = 'iframe></div></div></center>';
      var newStr = str.replace(/<iframe/g, replace1);
      newStr = newStr.replace(/iframe>/g, replace2);
      return newStr;
    },
    setForCheckBox(ele, key, stateKey) {
      //チェックボックス用のデータを作成
      if (ele[key] != undefined && ele[key] != '') {
        const arr = this.getArrayFromCellData(ele[key]); //配列の取得
        const obj = this.getObjForCheckBox(arr); //チェックボックス制御用のオブジェクトを作成
        const path = 'cont';
        this.$store.commit('update_store', {
          path: path,
          key: stateKey,
          data: obj,
        });
      }
    },
    setForCheckBoxForNewData(ele, key, stateKey) {
      //チェックボックス用のデータを作成
      if (ele[key] != undefined && ele[key] != '') {
        const obj = this.getObjForCheckBox(ele[key]).filter(
          (item) => item.text !== '',
        ); //チェックボックス制御用のオブジェクトを作成
        const path = 'cont';
        this.$store.commit('update_store', {
          path: path,
          key: stateKey,
          data: obj,
        });
      }
    },
    getArrayFromCellData(data) {
      //SSの@付きデータを配列化する。
      const arr = data.split('@');
      arr.shift(); //先頭は空なので削除
      return arr;
    },
    getObjForCheckBox(arr) {
      //チェックボックス制御で必要なオブジェクトを返す
      var obj = [];
      for (const i in arr) {
        const rand = this.getRandomString(10); // 生成する文字列の長さ
        const data = {
          randId: rand,
          checked: false,
          color: '',
          text: arr[i],
          value: arr[i],
        };
        obj.push(data);
      }
      return obj;
    },
    getRandomString(N) {
      //ランダムな文字列（N桁）を作成する
      var S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      const rand = Array.from(Array(N))
        .map(() => S[Math.floor(Math.random() * S.length)])
        .join('');
      return rand;
    },
    getNumberOfChoices(correct, dummy, ele) {
      //correctとdummyの出題数を決定する。

      let amountChoices = 6; //規定値は選択肢ヒントありで、選択肢6個
      if (Object.keys(this.option).length == 0) {
        //※SearchからだとsaveData.optionは空だよ！！
        amountChoices = 10; //検索時はたくさん選択肢を設定しよう
      } else {
        const isHint = this.option.selHint.value;
        if (isHint == false) {
          amountChoices = 10;
        } //選択肢ヒントなしなら、選択肢10個
      }

      const amountItems = correct.length + dummy.length;
      if (amountItems <= amountChoices) {
        return { correct: correct.length, dummy: dummy.length };
      }

      var maxAmountCorrect = amountChoices - 1; //モードで規定されているchoicesが5であれば、correctは最大で4つ用意できる。
      if (correct.length <= maxAmountCorrect) {
        //出題可能数（maxCollectAmount）が4でも、
        maxAmountCorrect = correct.length; //正解数（correct.length）が2の場合は、正解数の上限を2とする
      }

      const queFormat = ele['queFormat'];
      if (queFormat == 'perfectMatch' || queFormat == 'fixedAnswer') {
        //形式が、完全一致と回答数固定なら、全部使う
        var amountCorrect = correct.length;
      } else {
        amountCorrect = this.getRandomNumber(1, maxAmountCorrect); //1以上最大出題数以下で、整数乱数を取得
      }

      var amountDummy = amountChoices - amountCorrect; //amountDummyを算出する
      if (dummy.length <= amountDummy) {
        //出題予定数（amountDummy）が5でも
        amountDummy = dummy.length; //ダミー数が3であれば、ダミーの出題数を3とする。残りの2は正解数とする。
        amountCorrect = amountCorrect + (amountDummy - dummy.length); //不足分をamountCollectに加算する
      }

      return { correct: amountCorrect, dummy: amountDummy };
    },
    getRandomNumber(min, max) {
      //min以上max以下の整数乱数を返す
      return Math.floor(Math.random() * (max + 1 - min)) + min;
    },
    getCorrectData(originalArray, num) {
      //決定された出題数に従い、正解の新しい配列を作成
      var arr = originalArray.slice();
      var newArr = [];
      for (var i = 0; i < num; i++) {
        newArr.push(arr[i]);
      }
      return newArr;
    },
    getNewArrayByRandomSampling(originalArray, num) {
      //オリジナルの配列からnumだけランダムに抽出して新配列を作成
      if (originalArray.length == 0 || num == 0) {
        return []; //ダミーが存在しないパターンもある。
      }
      var arr = originalArray.slice(); //これからシャッフルするので値渡し（参照渡し不可）でコピーする
      arr = this.shuffle(arr);
      var newArr = [];
      for (var i = 0; i < num; i++) {
        newArr.push(arr[i]);
      }
      return newArr;
    },
    setNumberOfSelect() {
      //解答すべき選択肢数の表示を制御する
      if (Object.keys(this.option).length == 0) {
        //※SearchからだとsaveData.optionは空だよ！！
        //
      } else {
        const isHint = this.option.selHint.value;
        if (isHint == false) {
          this.contBtn.numberOfSelect = 0;
          return;
        }
      }
      const num = this.cont.correctData.length;
      this.contBtn.numberOfSelect = num;
    },
    //共通
    //======================================================
    setCheckBoxItems(correct, dummy) {
      //選択肢を生成し、シャッフルして、セットする
      var arrays = [correct, dummy];
      var checkBoxItems = this.arrayIntegration(arrays);
      checkBoxItems = this.shuffle(checkBoxItems);
      this.contBtn.checkBoxItems = checkBoxItems;
    },
    arrayIntegration(arrays) {
      //arrays中にある配列を統合する
      var master = [];
      for (var i = 0; i < arrays.length; i++) {
        const arr = arrays[i];
        for (var j = 0; j < arr.length; j++) {
          master.push(arr[j]);
        }
      }
      return master;
    },
    shuffle(list) {
      var i = list.length;
      while (--i) {
        var j = Math.floor(Math.random() * (i + 1));
        if (i == j) continue;
        var k = list[i];
        list[i] = list[j];
        list[j] = k;
      }
      return list;
    },

    //採点
    //======================================================
    contQ_scoringMain() {
      //scoringと1問の終了時間を保存、ContBtnから来ています。
      this.$store.commit('update_store', {
        path: 'cont',
        key: 'endQTime',
        data: new Date().getTime(),
      });
      const ele = this.cont['qOriginal'];
      const queFormat = ele['queFormat'];
      var judge = false;
      if (queFormat == 'perfectMatch') {
        //形式が、完全一致
        judge = this.judgePerfectMatch();
        this.showCollectPerfectMatch();
      } else {
        //一般とfixedAnswerは同様に採点できる。fixedAnswerは回答数を作成する時だけ必要
        judge = this.judgeAnswer();
        this.showCorrectAnswer();
      }
      return judge;
    },
    judgePerfectMatch() {
      //完全一致型の採点
      let userAns = this.contBtn.selCheckBoxItems;
      userAns = userAns.filter((data) => data !== ''); //チェックをON・OFFすると配列に""が混ざる
      const correctData = this.cont.correctData;
      if (correctData.length != userAns.length) {
        return false;
      } //正解をもとにユーザ回答を検証するが、正解（1,2,3）をもとにユーザ回答（1,2,3,4）を調べてもtrueとなってしまうので、配列の長さで絞り込む。
      var flag = true;
      for (var i = 0; i < correctData.length; i++) {
        //正答配列を繰り返し操作。ユーザ配列の要素と一致しているか確認
        if (correctData[i] != userAns[i]) {
          flag = false;
        }
      }
      return flag;
    },
    showCollectPerfectMatch() {
      //完全一致型の正解を表示する
      const correctData = this.cont.correctData;
      const items = this.contBtn.checkBoxItems;
      for (var i = 0; i < items.length; i++) {
        const randId = items[i]['randId'];
        const ind = correctData.findIndex((data) => data['randId'] == randId);
        const correctNum = ind + 1; //ind+1が正解順を表す
        if (ind == -1) {
          //indが無い＝正解ではない、かつ
          if (items[i]['checked']) {
            //ユーザがチェックしている、ならば
            this.contBtn.checkBoxItems[i]['text'] =
              '【✕】' + this.contBtn.checkBoxItems[i]['text']; //不正解とし、ナンバリングされているので、valueではなくtextを使用する
          }
        } else {
          //正解配列には存在する、かつ
          if (items[i]['checked']) {
            //ユーザーがチェックしている、かつ
            const userNum = items[i]['perfectMatch'];
            if (userNum == correctNum) {
              //ユーザ解答番号と、perfectMatchの番号が同じ、ならば
              this.contBtn.checkBoxItems[i]['color'] = 'red'; //正解を赤文字で表示する
              this.contBtn.checkBoxItems[i]['text'] =
                '【〇】' + this.contBtn.checkBoxItems[i]['text'];
            } else {
              this.contBtn.checkBoxItems[i]['text'] =
                '【✕】【' +
                userNum +
                '】→【' +
                correctNum +
                '】' +
                this.contBtn.checkBoxItems[i]['value'];
            }
          } else {
            this.contBtn.checkBoxItems[i]['color'] = 'red';
            this.contBtn.checkBoxItems[i]['text'] =
              '【✔】【' +
              correctNum +
              '】' +
              this.contBtn.checkBoxItems[i]['value']; //チェックがついていれば正解
          }
        }
      }
    },
    judgeAnswer() {
      //組合せ型の採点
      const userAns = this.contBtn.selCheckBoxItems;
      const correctData = this.cont.correctData;
      if (correctData.length != userAns.length) {
        return false;
      } //正解をもとにユーザ回答を検証するが、正解（1,2,3）をもとにユーザ回答（1,2,3,4）を調べてもtrueとなってしまう。配列の長さで絞り込む。
      var flag = true;
      for (var i = 0; i < correctData.length; i++) {
        //正答配列を繰り返し操作。ユーザ配列に値があるか検索する。-1が返ってきたら不正解
        const randId = correctData[i]['randId'];
        const ind = userAns.findIndex((data) => data['randId'] == randId);
        if (ind == -1) {
          flag = false;
        }
      }
      return flag;
    },
    showCorrectAnswer() {
      //組合せ型の正解を表示する
      const correctData = this.cont.correctData;
      const items = this.contBtn.checkBoxItems;
      for (var i = 0; i < items.length; i++) {
        const randId = items[i]['randId'];
        const ind = correctData.findIndex((data) => data['randId'] == randId);
        if (ind == -1) {
          if (items[i]['checked']) {
            this.contBtn.checkBoxItems[i]['text'] =
              '【✕】' + this.contBtn.checkBoxItems[i]['value']; //チェックがついていれば不正解
          }
        } else {
          this.contBtn.checkBoxItems[i]['color'] = 'red'; //正解を赤文字で表示する
          if (items[i]['checked']) {
            this.contBtn.checkBoxItems[i]['text'] =
              '【〇】' + this.contBtn.checkBoxItems[i]['value']; //チェックがついていれば正解
          } else {
            this.contBtn.checkBoxItems[i]['text'] =
              '【✔】' + this.contBtn.checkBoxItems[i]['value']; //チェックがついていれば正解
          }
        }
      }
    },

    //次の問題へ、終了処理（FSへのデータ登録）
    //======================================================
    //ContBtnScoringで正解・不正解を押すと伝令される。
    contQ_saveAndNext(isTrue) {
      const Q = this.perm.questionData;
      const qNum = this.perm.qNum;
      const ele = Q[qNum];
      const sectionId = ele['sectionId'];
      const cont = this.getNecessaryCont();
      const contBtn = this.utils_deepCopy(this.contBtn); //stateのcontBtnを値渡しする。必ず値渡し！

      let newStateObj = { currentState: null, log: null };
      if (this.gf_isLogin()) {
        newStateObj = this.updateState(isTrue, ele);
      }

      const data = {
        id: ele.id,
        qNum: qNum,
        sectionId: sectionId,
        isCorrect: isTrue,
        cont: cont, //この問題を制御したContをすべて格納する
        contBtn: contBtn, //ユーザの回答記録を格納。終了後の検索時に使用する
        currentState: newStateObj.currentState, //最後に、kamokuName.qStateに統合させる
        stateLog: newStateObj.log,
      };

      this.$store.commit('update_store', {
        path: 'saveData.answerData',
        key: qNum,
        data: data,
      });

      //終了判断
      if (this.perm.qNum >= this.perm.questionData.length - 1) {
        alert('問題がすべて終了しました！結果を表示します。');
        this.contQ_finish();
      } else {
        this.perm.qNum++; //問題番号の表示
        scrollTo(0, 0); //ページトップへ
        this.contQ_setQuestionMain();
      }
    },
    //contをそのまま格納するとFSのデータサイズが無駄に大きくなってしまう。
    getNecessaryCont() {
      //contをそのまま格納するとFSのデータサイズが無駄に大きくなってしまう。
      const obj = {
        questionId: this.cont.questionId,
        startQTime: this.cont.startQTime,
        endQTime: this.cont.endQTime,
      };
      return obj;
    },
    //問題のnewStateとlogを作成
    updateState(judge, ele) {
      //問題の解答ログを保存しています
      const currentState = ele.qState.currentState; //問題作成時にfilterQuestionsByStateで挿入しています。
      let newState = 'incorrect';
      if (judge) {
        if (currentState == 'unanswered' || currentState == 'incorrect') {
          newState = 'correctOnce';
        }
        if (
          currentState == 'correctOnce' ||
          currentState == 'correctConstant'
        ) {
          newState = 'correctConstant';
        }
      } else {
        //判定がfalseなら、かならずincorrectになる
        newState = 'incorrect';
      }

      const startQTime = this.$store.getters.getState('cont.startQTime');
      const endQTime = this.$store.getters.getState('cont.endQTime');
      const sec = Math.round((endQTime - startQTime) / 1000);
      const logData = {
        log: {
          date: new Date().getTime(),
          isCorrect: judge,
          oldState: currentState,
          newState: newState,
          sec: sec,
        },
      };

      return { currentState: newState, log: logData };
    },
    async contQ_finish() {
      this.$store.commit('loading', true);

      //終了時刻の記入
      await this.$store.commit('update_store', {
        path: 'saveData',
        key: 'endTime',
        data: new Date().getTime(),
      });

      if (this.gf_isLogin()) {
        await this.contQ_createUserFSAndSave();
      }

      await this.$store.commit('leavePage', true);
      this.$store.commit('loading', false);
      await this.$router.push('/result');
    },
    //userデータの作成、FSへのmerge、FSからの再取得
    async contQ_createUserFSAndSave() {
      const kamokuName = this.saveData.selKamokuName;
      const date_ms = new Date().getTime();

      //結果表示の段階では、回答ログも再現する。しかしそのデータをFSには格納しない。FSのデータ量の問題。
      this.$store.commit('update_store', {
        path: 'user.fs.' + kamokuName + '.activityLogs.data',
        key: date_ms,
        data: { saveData: this.saveData },
      });

      //解答データ（saveData.answerData）をもとにUserFSにnewStateを格納する
      this.integrateNewStateIntoUserFS();

      //最新版のqStateから統計情報を取得する。{unanswered:0, incorrect:0, correctOnce:0, correctConstant:0}
      this.$store.commit('update_store', {
        path: `user.fs.${kamokuName}.stateStatistics.data`,
        key: date_ms,
        data: this.gf_getStateStatistics(kamokuName),
      });

      const mergeObj = {
        activityLogs: this.getActivityLogs(this.saveData, date_ms),
        qState: this.user.fs[kamokuName].qState.data,
        stateStatistics: this.user.fs[kamokuName].stateStatistics.data,
      };

      await this.utils_merge_userFS(kamokuName, mergeObj);
      return true;
    },
    getActivityLogs(saveData, date) {
      const save = this.utils_deepCopy(saveData);
      const answerData = save.answerData;
      var obj = {};
      Object.keys(answerData).forEach((ind) => {
        const id = answerData[ind].id;
        const isCorrect = answerData[ind].isCorrect;
        obj[ind] = {
          id: id,
          isCorrect: isCorrect,
        };
      });
      save.answerData = obj;
      const activityLogs = { [date]: save };
      return activityLogs;
    },
    integrateNewStateIntoUserFS() {
      const answerData = this.saveData.answerData;
      const kamokuName = this.saveData.selKamokuName;
      Object.keys(answerData).forEach((ind) => {
        const ele = answerData[ind];
        const qid = ele.id;
        const stateLog = ele.stateLog; //{ log:{data} }

        this.$store.commit('update_store', {
          path: `user.fs.${kamokuName}.qState.data.${qid}`,
          key: 'currentState',
          data: ele.currentState,
        });

        const key = stateLog.log.date;
        delete stateLog.log.date; //logの中身にdateは不要なので削除する
        this.$store.commit('update_store', {
          path: `user.fs.${kamokuName}.qState.data.${qid}.logs`,
          key: key,
          data: stateLog.log,
        });
      });
    },
    getHtmlCommentStr(str) {
      const imgContainedStr = str.replace(/{{.+}}/g, (match) => {
        const url = this.utils_create_storageUrl(match.replace(/{{|}}/g, ''));
        return `<img src="${url}" style="max-width: 100%;"/>`;
      });

      return imgContainedStr;
    },
    async getQuestionImagePaths(kamokuName) {
      const dirPath = `images/${kamokuName}/question`;
      const allFiles = await this.utils_get_storageAllFiles(dirPath);

      const imagePaths = allFiles.items
        .filter((item) => item.name.includes(this.cont.questionId))
        .map((targetFile) => this.utils_create_storageUrl(targetFile.fullPath));

      return imagePaths;
    },
  },
};
